import { DateTime } from 'luxon'

const useNotifications = function (props) {
  return {
    formatDateTime (timestamp) {
      return DateTime.fromISO(timestamp).setLocale(props.language).toLocaleString(DateTime.DATETIME_MED)
    }
  }
}

export { useNotifications }
