import { reactive } from 'vue'

const useSearch = function (props, { emit }, searchInputRef) {
  const data = reactive({
    text: ''
  })

  const actions = {
    onSearchContainerClick (event) {
      emit('searchClick', event)
    },
    changeSearchActive (event) {
      emit('searchToggle', event)
    },
    onSearchKeydown (event) {
      actions.changeSearchActive(event)
      const searchValue = data.text
      data.text = ''
      emit('search', searchValue)
    },
    onEnter () {
      if (searchInputRef.value) {
        searchInputRef.value.$el.focus()
      }
    }
  }

  return { data, ...actions }
}

export { useSearch }
