const messages = {
  de: {
    app: {
      dispatch: {
        status: {
          notSet: "",
          saving: 'Speicherung...',
          saved: 'Gespeichert',
          error: 'Speicherung fehlgeschlagen!'
        }
      }
    }
  },
  en: {
    app: {
      dispatch: {
        status: {
          notSet: "",
          saving: 'Saving...',
          saved: 'Saved',
          error: 'Saving failed!'
        }
      }
    }
  }
}

export default messages
