<template>
  <SelectButton v-model="selectedLanguage" :options="languageOptions" dataKey="key" optionLabel="name">
    <template #option="{ option }">
      <i v-if="option.flag" :class="[{ 'mr-2': !icon, 'm-auto': icon }, `flag-icon flag-icon-${option.flag}`]"></i>
      <template v-if="!icon">{{ option.name }}</template>
    </template>
  </SelectButton>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppLanguage',
  props: {
    options: { type: Array, required: true },
    modelValue: { type: Object },
    icon: { type: Boolean, default: null }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const { modelValue } = toRefs(props)

    const selectedLanguage = ref(props.modelValue)
    const languageOptions = computed(() => props.options.map(option => ({ ...option, name: t(option.name) })))

    watch(selectedLanguage, value => emit('update:modelValue', value))
    watch(modelValue, value => {
      selectedLanguage.value = { ...value, name: t(value.name) }
    })

    return { selectedLanguage, languageOptions }
  }
}
</script>
