import { MlApi } from '../api'

const state = {
  metaLearning: null,
  trainingSettings: null,
  training: null,
  evaluation: null,
  evaluationAnomalies: null
}

const getters = {
  metaLearning: state => state.metaLearning,
  metaLearningProgress: state => state.metaLearning ? state.metaLearning.progress : null,
  metaLearningResult: state => {
    if (state.metaLearning && state.metaLearning.result && state.metaLearning.result.baseLearner) {
      return state.metaLearning.result.baseLearner
    } else {
      return null
    }
  },
  trainingSettings: state => state.trainingSettings,
  training: state => state.training,
  trainingProgress: state => state.training ? state.training.progress : null,
  trainingResult: state => state.training && state.training.result && state.training.result ? state.training.result : null,
  evaluation: state => state.evaluation,
  evaluationAnomalies: state => state.evaluationAnomalies
}

const mutations = {
  setMetaLearning: (state, value) => (state.metaLearning = value),
  setTrainingSettings: (state, value) => (state.trainingSettings = value),
  setTraining: (state, value) => (state.training = value),
  setEvaluation: (state, value) => (state.evaluation = value),
  setEvaluationAnomalies: (state, value) => (state.evaluationAnomalies = value)
}

const actions = {
  clear ({ commit }) {
    commit('setMetaLearning', null)
    commit('setTrainingSettings', null)
    commit('setTraining', null)
    commit('setEvaluation', null)
    commit('setEvaluationAnomalies', null)
    return Promise.resolve()
  },
  loadMetaLearningStatus ({ commit }, projectId) {
    if (projectId) {
      return MlApi.getMetaLearningStatus(projectId).then(metaLearning => {
        commit('setMetaLearning', metaLearning)
        return metaLearning
      })
    } else {
      return Promise.resolve()
    }
  },
  startMetaLearning ({ commit }, projectId) {
    if (projectId) {
      commit('setMetaLearning', null)
      commit('setTrainingSettings', null)
      commit('setTraining', null)
      commit('setEvaluation', null)
      commit('setEvaluationAnomalies', null)

      return MlApi.startMetaLearning(projectId)
        .then(metaLearning => {
          commit('setMetaLearning', metaLearning)
          return metaLearning
        })
        .then(() => MlApi.getMetaLearningStatus(projectId))
        .then(metaLearning => {
          commit('setMetaLearning', metaLearning)
          return metaLearning
        })
    } else {
      return Promise.resolve()
    }
  },
  loadTrainingSettings ({ commit }, projectId) {
    if (projectId) {
      return MlApi.loadTrainingSettings(projectId).then(trainingSettings => {
        commit('setTrainingSettings', trainingSettings)
        return trainingSettings
      })
    } else {
      return Promise.resolve()
    }
  },
  saveTrainingSettings ({ commit }, { projectId, trainingSettings }) {
    if (projectId && trainingSettings) {
      return MlApi.saveTrainingSettings(projectId, trainingSettings).then(trainingSettings => {
        commit('setTrainingSettings', trainingSettings)
        return trainingSettings
      })
    } else {
      return Promise.resolve()
    }
  },
  loadTrainingStatus ({ commit }, projectId) {
    if (projectId) {
      return MlApi.getTrainingStatus(projectId).then(trainingStatus => {
        commit('setTraining', trainingStatus)
        return trainingStatus
      })
    } else {
      return Promise.resolve()
    }
  },
  startTraining ({ commit }, projectId) {
    if (projectId) {
      commit('setEvaluation', null)
      commit('setEvaluationAnomalies', null)

      return MlApi.startTraining(projectId)
        .then(training => {
          commit('setTraining', training)
          return training
        })
        .then(() => MlApi.getTrainingStatus(projectId))
        .then(training => {
          commit('setTraining', training)
          return training
        })
    } else {
      return Promise.resolve()
    }
  },
  loadEvaluation ({ commit }, projectId) {
    if (projectId) {
      return MlApi.getEvaluation(projectId).then(evaluation => {
        commit('setEvaluation', evaluation)
        return evaluation
      })
    } else {
      return Promise.resolve()
    }
  },
  loadEvaluationAnomalies ({ commit }, { projectId, modelId }) {
    if (projectId && modelId) {
      commit('setEvaluationAnomalies', null)

      return MlApi.getEvaluationAnomalies(projectId, modelId).then(evaluationAnomalies => {
        commit('setEvaluationAnomalies', evaluationAnomalies)
        return evaluationAnomalies
      })
    } else {
      return Promise.resolve()
    }
  },
  selectEvaluationAnomalieWarnBorder ({ commit }, { projectId, modelId, warnBorder }) {
    if (projectId && modelId && warnBorder !== null) {
      return MlApi.selectEvaluationAnomalieWarnBorder(projectId, modelId, warnBorder).then(evaluationAnomalies => {
        commit('setEvaluationAnomalies', evaluationAnomalies)
        return evaluationAnomalies
      })
    } else {
      return Promise.resolve()
    }
  },
  selectEvaluationAnomalies ({ commit }, { projectId, modelId, selectedAnomalies }) {
    if (projectId && modelId && selectedAnomalies !== null) {
      return MlApi.selectEvaluationAnomalies(projectId, modelId, selectedAnomalies).then(evaluationAnomalies => {
        commit('setEvaluationAnomalies', evaluationAnomalies)
        return evaluationAnomalies
      })
    } else {
      return Promise.resolve()
    }
  }
}

const data = { namespaced: true, state, getters, mutations, actions }

export default data
