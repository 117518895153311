import { createApp, h } from 'vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'

import VueAxios from 'vue-axios'
import axios from '@/plugins/axios'

import { createPrimeVue } from '@/plugins/primevue'
import AppWrapper from '@/dockone/app/AppWrapper'
import DispatchInputWrapper from '@/dockone/components/dispatcher/DispatchInputWrapper'
import DispatchStatus from '@/dockone/components/dispatcher/DispatchStatus'

const app = createApp({
  render () { return h(AppWrapper) }
})

app.config.globalProperties.name = process.env.APP_NAME
app.config.globalProperties.version = process.env.APP_VERSION
app.config.globalProperties.baseUrl = process.env.APP_BASE_URL

createPrimeVue(app)
app.component('DispatchInputWrapper', DispatchInputWrapper)
app.component('DispatchStatus', DispatchStatus)

app.use(router)
app.use(store)
app.use(i18n)
app.use(VueAxios, axios)

store.commit('app/init', {
  language: 'de',
  layoutMode: 'light',
  layoutTheme: 'lenze',
  topbarTheme: 'lenze',
  menuMode: 'horizontal',
  menuTheme: 'lenze',
  inlineMenuPosition: 'bottom',
  inputStyle: 'outlined',
  ripple: true,
  rtl: false
})

app.mount('#app')
