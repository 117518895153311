const messages = {
  de: {
    app: {
      language: {
        label: {
          _: 'Unknown',
          german: 'Deutsch',
          english: 'Englisch'
        }
      },
      config: {
        layoutMode: {
          options: {
            light: 'Hell',
            dark: 'Dunkel'
          }
        }
      },
      user: {
        unknown: 'Unbekannter User',
        logout: {
          label: 'Abmelden'
        }
      },
      form: {
        label: {
          ok: 'Ok',
          cancel: 'Abbrechen',
          confirm: 'Bestätigen',
          forward: 'Weiter',
          back: 'Zurück',
          noData: 'Keine Daten',
          choose: 'Auswählen',
          upload: 'Hochladen'
        },
        pagination: 'Zeige {\'{first}\'} bis {\'{last}\'} von {\'{totalRecords}\'}'
      }
    }
  },
  en: {
    app: {
      language: {
        label: {
          _: 'Unknown',
          german: 'German',
          english: 'English'
        }
      },
      config: {
        layoutMode: {
          options: {
            light: 'Light',
            dark: 'Dark'
          }
        }
      },
      user: {
        unknown: 'Unknown User',
        logout: {
          label: 'Logout'
        }
      },
      form: {
        label: {
          ok: 'Ok',
          cancel: 'Cancel',
          confirm: 'Confirm',
          forward: 'Next',
          back: 'Back',
          noData: 'No Data',
          choose: 'Choose',
          upload: 'Upload'
        },
        pagination: 'Showing {\'{first}\'} to {\'{last}\'} of {\'{totalRecords}\'}'
      }
    }
  }
}

export default messages
