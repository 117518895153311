<template>
  <Sidebar v-model:visible="d_expanded" :position="isRTL ? 'left' : 'right'" :showCloseIcon="false"
           :baseZIndex="1000" class="layout-rightmenu p-sidebar-sm fs-small py-3" :class="isRTL ? 'p-pl-0 p-pr-3' : 'p-pl-3 p-pr-0'"
           @hide="$emit('hide', d_expanded)" @click="$emit('contentClick',$event)">
    <slot></slot>
  </Sidebar>
</template>

<script>
export default {
  name: 'AppRightPanel',
  props: { expanded: { type: Boolean } },
  emits: ['contentClick', 'hide'],
  data () {
    return {
      d_expanded: null
    }
  },
  computed: {
    isRTL () {
      return this.$appState.RTL
    }
  },
  watch: {
    expanded (value) {
      this.d_expanded = value
    }
  }
}
</script>
