<template>
  <div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
      <a class="layout-topbar-logo" href="/">
        <img id="logo" src="layout/images/logo-light.svg" alt="logo" style="height: 2.25rem">
      </a>

      <a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
        <i class="pi pi-chevron-right"></i>
      </a>

      <a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
        <i class="pi pi-ellipsis-v fs-large"></i>
      </a>
    </div>

    <div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
      <div class="layout-topbar-actions-left">
        <slot name="menu"></slot>
      </div>

      <div class="layout-topbar-actions-right">
        <ul class="layout-topbar-items">
          <li v-if="searchVisible" class="layout-topbar-item layout-search-item">
            <a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'search')" v-ripple>
              <i class="pi pi-search fs-large"></i>
            </a>

            <transition name="layout-search-panel" @enter="search.onEnter">
              <div class="layout-search-panel p-inputgroup" v-show="searchActive" @click="search.onSearchContainerClick">
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                <InputText ref="searchInput" type="text" :placeholder="$t('app.topbar.search.placeholder')" v-model="search.data.text"
                           @keydown.enter="search.onSearchKeydown($event)" />
                <span class="p-inputgroup-addon">
									<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="search.changeSearchActive"></Button>
								</span>
              </div>
            </transition>
          </li>

          <slot name="rightItems"></slot>

          <li v-if="notifications" class="layout-topbar-item notifications">
            <a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'notifications')" v-ripple>
              <span v-if="notifications.length > 0" class="p-overlay-badge" v-badge.warning><i class="pi pi-bell fs-large"></i></span>
              <span v-else class="p-overlay-badge"><i class="pi pi-bell fs-large"></i></span>
            </a>

            <transition name="layout-topbar-action-panel">
              <ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'notifications'">
                <li class="mb-3">
                  <span class="px-3 fs-small">
                    {{ t('app.topbar.notifications.headline.title') }}
                    <strong>{{ t('app.topbar.notifications.headline.messagesCount', { n: notifications.length }, notifications.length) }}</strong>
                    {{ t('app.topbar.notifications.headline.messages', notifications.length) }}
                  </span>
                </li>
                <template v-for="(item, index) in notifications" :key="index">
                  <slot name="notificationItem" :item="item">
                    <li class="layout-topbar-action-item">
                      <div class="flex flex-row align-items-center">
                        <img v-if="item.image" :src="item.image" />
                        <div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}" style="flex-grow: 1;">
                          <div class="flex align-items-center justify-content-between mb-1">
                            <span class="fs-small font-bold">{{ item.name }}</span>
                            <small v-if="item.datetime">{{ notification.formatDateTime(item.datetime) }}</small>
                          </div>
                          <span class="fs-small">{{ item.text }}</span>
                        </div>
                      </div>
                    </li>
                  </slot>
                </template>
              </ul>
            </transition>
          </li>

          <li v-if="user" class="layout-topbar-item">
            <a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple"
               @click="onTopbarItemClick($event, 'profile')" v-ripple>
              <img v-if="user.image" :src="user.image" alt="avatar" style="width: 32px; height: 32px;">
              <Avatar v-else icon="pi pi-user" size="large" shape="circle" style="background-color: inherit; color: inherit" />
            </a>

            <transition name="layout-topbar-action-panel">
              <ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
                <slot name="userItems"></slot>
                <slot name="userLayoutMode"></slot>
                <slot name="userLanguage"></slot>
                <slot name="userLogout"></slot>
              </ul>
            </transition>
          </li>

          <li v-if="rightPanelVisible" class="layout-topbar-item">
            <a class="layout-topbar-action rounded-circle p-ripple" @click="onRightPanelButtonClick($event)" v-ripple>
              <i class="pi fs-large" :class="{'pi-arrow-left': !isRTL, 'pi-arrow-right': isRTL}"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSearch } from './app-topbar-search'
import { useNotifications } from './app-topbar-notifications'
import messages from './app-topbar-i18n'

export default {
  props: {
    language: { type: String, default: 'de' },
    horizontal: { type: Boolean, default: false },
    topbarMenuActive: { type: Boolean, default: false },
    activeTopbarItem: { type: String },
    mobileTopbarActive: { type: Boolean },
    rightPanelVisible: { type: Boolean, default: null },
    searchVisible: { type: Boolean, default: null },
    searchActive: { type: Boolean },
    notifications: { type: Array, default: null },
    user: { type: Object }
  },
  emits: ['menuButtonClick', 'topbarItemClick', 'rightPanelButtonClick', 'topbarMobileActive', 'searchClick', 'searchToggle', 'search'],
  setup (props, { emit }) {
    const vueInstance = getCurrentInstance()
    const { t } = useI18n({ useScope: 'global', messages })

    const isRTL = computed(() => vueInstance.appContext.config.globalProperties.$appState.RTL)

    const topbarActions = {
      onMenuButtonClick (event) {
        emit('menuButtonClick', event)
      },
      onTopbarItemClick (event, item) {
        if (item === 'search') {
          emit('searchToggle', event)
        }
        emit('topbarItemClick', { originalEvent: event, item: item })
      },
      onTopbarMobileButtonClick (event) {
        emit('topbarMobileActive', event)
      },
      onRightPanelButtonClick (event) {
        emit('rightPanelButtonClick', event)
      }
    }

    const searchInputRef = ref()
    const search = useSearch(props, { emit }, searchInputRef)
    const notification = useNotifications(props)

    return { t, isRTL, ...topbarActions, search, notification }
  }
}
</script>
