<template>
  <App :languages="appConfig.languages" defaultLang="de" :themes="appConfig.themes" defaultTheme="lenze"
       :topbarThemes="appConfig.topbarThemes" defaultTopbarTheme="lenze"
       :menuThemes="appConfig.menuThemes" defaultMenuTheme="lenze"
       defaultLayoutMode="light" defaultMenuMode="horizontal" defaultInlineMenuPosition="bottom" defaultInputStyle="outlined"
       :notifications="null" :topbarMenu="appConfig.pages" layoutModeSelection languageSelection>
    <template v-slot:topbarUserItems="{ user, isRtl }">
      <a class="flex flex-row align-items-center" :class="{'ml-3': !isRtl, 'mr-3': isRtl}">
        <Avatar icon="pi pi-user" shape="circle" />
        <span class="flex flex-column" :class="{'ml-3': !isRtl, 'mr-3': isRtl}">
          <span class="font-bold">{{ user.displayName }}</span>
          <small v-if="user.description">{{ user.description }}</small>
        </span>
      </a>
      <Divider class="mt-3 mb-2" />
    </template>
    <template v-slot:topbarMenu="{ items }">
      <AppTabMenu :model="items" class="layout-tabmenu" />
    </template>
    <template v-slot:topbarRightItems>
      <li class="layout-topbar-item profi-mode ml-1 mr-1">
        <span class="mr-2">Profi Modus</span>
        <InputSwitch :modelValue="false" />
      </li>
    </template>
    <template v-slot:breadcrumb>
      <Button icon="pi pi-share-alt" class="p-button-rounded p-button-text p-button-plain mr-1"></Button>
    </template>
  </App>
</template>

<script>
import App from '@/dockone/app/App.vue'
import AppTabMenu from '@/dockone/app/topbar/AppTabMenu.vue'

import { reactive, ref, computed } from 'vue'
import { DateTime } from 'luxon'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default {
  name: 'AutoCBM',
  components: { AppTabMenu, App },
  setup () {
    const route = useRoute()
    const { t } = useI18n({ useScope: 'global' })

    const projectId = computed(() => route.params.id)
    const appConfig = reactive({
      languages: [{ key: 'de', flag: 'de', name: 'app.language.label.german' }, { key: 'en', flag: 'gb', name: 'app.language.label.english' }],
      themes: [
        { name: 'dockone', color: '#121212' },
        { name: 'lenze', color: '#209cdf' }
      ],
      topbarThemes: [
        { name: 'dockone', color: '#121212' },
        { name: 'lenze', color: '#209cdf' },
        { name: 'dark', color: '#363636' }
      ],
      menuThemes: [
        { name: 'dockone', color: '#ffffff' },
        { name: 'lenze', color: '#f7f7f7' },
        { name: 'dark', color: '#434b54' }
      ],
      pages: computed(() => [
        { label: t('autocbm.projects.navigation.label'), to: '/projects' },
        { label: t('autocbm.projects.management.navigation.label'), to: `/project/${projectId.value}` }
      ])
    })

    const profiMode = ref(true)
    const notifications = ref([
      {
        name: 'Jerome Bell',
        text: 'How to write content about your photographs?',
        image: 'demo/images/avatar/amyelsner.png',
        datetime: DateTime.now().toString()
      },
      {
        name: 'Cameron Williamson',
        text: 'Start a blog to reach your creative peak.',
        image: 'demo/images/avatar/annafali.png',
        datetime: DateTime.now().toString()
      },
      { name: 'Anna Miles', text: 'Caring is the new marketing.', image: 'demo/images/avatar/bernardodominic.png', datetime: DateTime.now().toString() },
      {
        name: 'Arlene Mccoy',
        text: 'Starting your traveling blog with Vasco.',
        image: 'demo/images/avatar/stephenshaw.png',
        datetime: DateTime.now().toString()
      }
    ])

    return { t, appConfig, profiMode, notifications, projectId }
  }
}
</script>
