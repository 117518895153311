const messages = {
  de: {
    app: {
      config: {
        title: 'Design Anpassung',
        layoutScale: {
          title: 'Layout Skalierung'
        },
        layoutMode: {
          title: 'Layout-Modus',
          options: {
            light: 'Hell',
            dark: 'Dunkel'
          }
        },
        menuMode: {
          title: 'Menü-Modus',
          options: {
            static: 'Statisch',
            overlay: 'Overlay',
            horizontal: 'Horizontal',
            slim: 'Slim'
          }
        },
        inlineMenuPosition: {
          title: 'Position des Inline-Menüs',
          options: {
            top: 'Oben',
            bottom: 'Unten',
            both: 'Beides'
          }
        },
        inputBackground: {
          title: 'Eingabe-Hintergrund',
          options: {
            outlined: 'Umrandet',
            filled: 'Gefüllt'
          }
        },
        ripple: {
          title: 'Ripple-Effekt'
        },
        rtl: {
          title: 'RTL'
        },
        menuThemes: {
          title: 'Menü Design',
          infoText: 'Die Menü Designs sind absichtlich nur im hellen Modus verfügbar, da große Flächen im dunklen Modus zu viel Helligkeit abgeben können.'
        },
        topbarThemes: {
          title: 'Topbar Design'
        },
        componentThemes: {
          title: 'Komponenten Design'
        }
      }
    }
  },
  en: {
    app: {
      config: {
        title: 'Theme Customization',
        layoutScale: {
          title: 'Layout Scale'
        },
        layoutMode: {
          title: 'Layout Mode',
          options: {
            light: 'Light',
            dark: 'Dark'
          }
        },
        menuMode: {
          title: 'Menu Mode',
          options: {
            static: 'Static',
            overlay: 'Overlay',
            horizontal: 'Horizontal',
            slim: 'Slim'
          }
        },
        inlineMenuPosition: {
          title: 'Inline Menu Position',
          options: {
            top: 'Top',
            bottom: 'Bottom',
            both: 'Both'
          }
        },
        inputBackground: {
          title: 'Input Background',
          options: {
            outlined: 'Outlined',
            filled: 'Filled'
          }
        },
        ripple: {
          title: 'Ripple Effect'
        },
        rtl: {
          title: 'RTL'
        },
        menuThemes: {
          title: 'Menu Themes',
          infoText: 'Menu themes are only available in light mode by design as large surfaces can emit too much brightness in dark mode.'
        },
        topbarThemes: {
          title: 'Topbar Themes'
        },
        componentThemes: {
          title: 'Component Themes'
        }
      }
    }
  }
}

export default messages
