import axios from 'axios'

class DockOneAuthenticationService {
  constructor () {
    this.axios = axios.create({ baseURL: process.env.APP_AUTHENTICATION_URL })
  }

  login (username, password) {
    return this.axios.post('', { username, password })
      .then(response => response.data)
      .then(tokens =>
        this.axios.get('/user', { headers: { Authorization: `Bearer ${tokens.accessToken}` } })
          .then(response => response.data)
          .then(profile => ({ profile, tokens })))
  }

  refresh (tokens) {
    if (!tokens.refreshToken) {
      return Promise.reject(new Error('Unauthorized'))
    }

    return this.axios.put('', tokens.refreshToken, { headers: { 'Content-Type': 'text/plain' } })
      .then(response => response.data)
      .then(tokens =>
        this.axios.get('/user', { headers: { Authorization: `Bearer ${tokens.accessToken}` } })
          .then(response => response.data)
          .then(profile => ({ profile, tokens })))
  }

  logout (tokens) {
    const { clientId, accessToken, refreshToken } = tokens
    if (clientId && accessToken && refreshToken) {
      return this.axios.request({ url: '', method: 'delete', data: { clientId, accessToken, refreshToken } })
    } else {
      return Promise.resolve()
    }
  }
}

const authenticationService = new DockOneAuthenticationService()

export default authenticationService
