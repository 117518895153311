<template>
  <AppSubmenu class="layout-menu" :items="model" :menuMode="menuMode" :menuActive="active" :isSlimOrHorItemClick="isSlimOrHorItemClick" :root="true"
              :parentMenuItemActive="true" :mobileMenuActive="mobileMenuActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
</template>

<script>
import AppSubmenu from './AppSubmenu'

export default {
  name: 'AppMenu',
  components: { AppSubmenu },
  props: {
    model: { type: Array },
    menuMode: { type: String },
    active: { type: Boolean },
    mobileMenuActive: { type: Boolean },
    isSlimOrHorItemClick: { type: Boolean }
  },
  emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
  methods: {
    onMenuClick (event) {
      this.$emit('menu-click', event)
    },
    onMenuItemClick (event) {
      this.$emit('menuitem-click', event)
    },
    onRootMenuItemClick (event) {
      this.$emit('root-menuitem-click', event)
    }
  }
}
</script>
