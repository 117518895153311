<template>
  <div class="layout-footer flex align-items-center p-4 shadow-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
