import axios from 'axios'


export class ProjectApiService {
  constructor (baseURL) {
    this.$http = axios.create({ baseURL })
  }

  search (query, offset, limit) {
    limit = 1000
    return this.$http.get('', { params: { offset, limit, query } })
      .then(response => response.data)
      .then(response => {
        return { offest: response.offset, limit: response.limit, count: response.results.length, totalCount: response.totalCount, data: response.results }
      })
  }

  get (projectId) {
    return this.$http.get(`/${projectId}`)
      .then(response => response.data)
  }

  create () {
    return this.$http.post('')
      .then(response => response.data)
  }

  rename (projectId, name) {
    return this.$http.put(`/${projectId}/name`, { name })
      .then(response => response.data)
  }

  selectDataSource (projectId, sourceId) {
    return this.$http.put(`/${projectId}/data/source`, { source: sourceId })
      .then(response => response.data)
      .then(data => ({ sourceId: data.source }))
  }

  selectDataSourceStructure (projectId, sourceStructure, measurement) {
    return this.$http.put(`/${projectId}/data/source-structure`, { sourceStructure, measurement })
      .then(response => response.data)
  }

  selectDataStartDateTime (projectId, startDateTime) {
    return this.$http.put(`/${projectId}/data/start-time`, { startDateTime })
      .then(response => response.data)
  }

  selectDataEndDateTime (projectId, endDateTime) {
    return this.$http.put(`/${projectId}/data/end-time`, { endDateTime })
      .then(response => response.data)
  }

  selectDataSetsVariables (projectId, variables) {
    return this.$http.put(`/${projectId}/data/variables`, { variables })
      .then(response => response.data)
  }

  finish (projectId) {
    return this.$http.post(`/${projectId}/finished`)
  }

  delete (projectId) {
    return this.$http.delete('/' + projectId)
  }
  getGrafanaSession () {
    return this.$http.get(`/grafana-session`)
      .then(response => response.data)
  }
}
