<template>
  <div class="p-inputgroup">
    <DispatchStatus :icon="icon" :loading="loading" :saving="saving" :saved="saved" :error="error" :disabled="disabled" :textIcon="textIcon" :text="text" />
    <slot name="default"></slot>
    <slot name="rightInput"></slot>
  </div>
</template>

<script>
import DispatchStatus from './DispatchStatus'
import { reactive, onMounted, onUpdated } from 'vue'

export default {
  name: 'DispatchInputWrapper',
  props: {
    icon: { type: String, default: '' },
    loading: { type: Boolean },
    saving: { type: Boolean },
    saved: { type: Boolean },
    error: { type: Boolean },
    disabled: { type: Boolean },
    textIcon: { type: Boolean },
    text: { type: Boolean }
  },
  components: { DispatchStatus },
  setup (props, { slots }) {
    const layout = {
      data: reactive({
        defaultInputVisible: false,
        rightInputVisible: false
      }),
      displaySlots () {
        layout.data.defaultInputVisible = !!slots.default
        layout.data.rightInputVisible = !!slots.rightInput
      }
    }

    onMounted(() => layout.displaySlots())
    onUpdated(() => layout.displaySlots())

    return { layout }
  }
}
</script>
