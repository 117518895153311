<template>
  <Button @click="appConfig.active = true" icon="pi pi-cog" v-if="!appConfig.active" class="layout-config-button" />
  <Sidebar ref="appConfigBar" v-model:visible="appConfig.active" :position="rtl ? 'left' : 'right'" :showCloseIcon="false" :baseZIndex="1000"
           class="layout-config p-sidebar-sm fs-small p-0">
    <div class="layout-config-panel flex flex-column">
      <div class="p-px-3 p-pt-3">
        <h5>{{ t('app.config.title') }}</h5>
      </div>

      <hr class="mb-0" />

      <div class="layout-config-options p-3">
        <h6>{{ t('app.config.layoutScale.title') }}</h6>
        <div class="flex align-items-center">
          <Button icon="pi pi-minus" @click="decrementScale()" class="p-button-rounded p-button-text"
                  :disabled="appConfig.scale === appConfig.scales[0]" />
          <i class="pi pi-circle-on m-1 scale-icon" v-for="s of appConfig.scales" :key="s" :class="{'scale-active': s === appConfig.scale}"></i>
          <Button icon="pi pi-plus" @click="incrementScale()" class="p-button-rounded p-button-text"
                  :disabled="appConfig.scale === appConfig.scales[appConfig.scales.length - 1]" />
        </div>

        <h6>{{ t('app.config.layoutMode.title') }}</h6>
        <div class="flex">
          <div class="flex align-items-center">
            <RadioButton id="light" name="layoutMode" value="light" :modelValue="layoutMode" @update:modelValue="changeLayoutMode" />
            <label for="layoutMode1" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.layoutMode.options.light') }}</label>
          </div>
          <div class="flex align-items-center" :class="{'ml-4': !rtl, 'mr-4': rtl}">
            <RadioButton id="dark" name="layoutMode" value="dark" :modelValue="layoutMode" @update:modelValue="changeLayoutMode" />
            <label for="layoutMode2" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.layoutMode.options.dark') }}</label>
          </div>
        </div>

        <h6>{{ t('app.config.menuMode.title') }}</h6>
        <div class="flex">
          <div class="flex flex-column">
            <div class="flex align-items-center">
              <RadioButton id="menuMode1" name="menuMode" value="static" :modelValue="menuMode" @update:modelValue="changeMenuMode" />
              <label for="menuMode1" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.menuMode.options.static') }}</label>
            </div>
            <div class="flex align-items-center mt-3">
              <RadioButton id="menuMode2" name="menuMode" value="horizontal" :modelValue="menuMode" @update:modelValue="changeMenuMode" />
              <label for="menuMode2" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.menuMode.options.horizontal') }}</label>
            </div>
          </div>
          <div class="flex flex-column" :class="{'ml-4': !rtl, 'mr-4': rtl}">
            <div class="p-d-flex p-ai-center">
              <RadioButton id="menuMode3" name="menuMode" value="overlay" :modelValue="menuMode" @update:modelValue="changeMenuMode" />
              <label for="menuMode3" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.menuMode.options.overlay') }}</label>
            </div>
            <div class="flex align-items-center mt-3">
              <RadioButton id="menuMode4" name="menuMode" value="slim" :modelValue="menuMode" @update:modelValue="changeMenuMode" />
              <label for="menuMode4" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.menuMode.options.slim') }}</label>
            </div>
          </div>
        </div>

        <h6>{{ t('app.config.inlineMenuPosition.title') }}</h6>
        <div class="flex">
          <div class="flex align-items-center">
            <RadioButton id="inlineMenuPosition1" name="inlineMenuPosition" value="top"
                         :modelValue="inlineMenuPosition" @update:modelValue="changeInlineMenuPosition" />
            <label for="inlineMenuPosition1" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.inlineMenuPosition.options.top') }}</label>
          </div>
          <div class="flex align-items-center" :class="{'ml-4': !rtl, 'mr-4': rtl}">
            <RadioButton id="inlineMenuPosition2" name="inlineMenuPosition" value="bottom"
                         :modelValue="inlineMenuPosition" @update:modelValue="changeInlineMenuPosition" />
            <label for="inlineMenuPosition2" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.inlineMenuPosition.options.bottom') }}</label>
          </div>
          <div class="flex align-items-center" :class="{'ml-4': !rtl, 'mr-4': rtl}">
            <RadioButton id="inlineMenuPosition3" name="inlineMenuPosition" value="both"
                         :modelValue="inlineMenuPosition" @update:modelValue="changeInlineMenuPosition" />
            <label for="inlineMenuPosition3" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.inlineMenuPosition.options.both') }}</label>
          </div>
        </div>

        <h6>{{ t('app.config.inputBackground.title') }}</h6>
        <div class="flex">
          <div class="flex align-items-center">
            <RadioButton id="input_outlined" name="inputstyle" value="outlined" :modelValue="inputStyle" @update:modelValue="changeInputStyle" />
            <label for="input_outlined" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.inputBackground.options.outlined') }}</label>
          </div>
          <div class="flex align-items-center" :class="{'ml-4': !rtl, 'mr-4': rtl}">
            <RadioButton id="input_filled" name="inputstyle" value="filled" :modelValue="inputStyle" @update:modelValue="changeInputStyle" />
            <label for="input_filled" :class="{'ml-2': !rtl, 'mr-2': rtl}">{{ t('app.config.inputBackground.options.filled') }}</label>
          </div>
        </div>

        <h6>{{ t('app.config.ripple.title') }}</h6>
        <InputSwitch :modelValue="ripple" @update:modelValue="changeRipple" />

        <template v-if="rtlConfigurable">
          <h6>{{ t('app.config.rtl.title') }}</h6>
          <InputSwitch :modelValue="rtl" @update:modelValue="changeRtl" />
        </template>

        <h6>{{ t('app.config.menuThemes.title') }}</h6>
        <div v-if="layoutMode !== 'dark'" class="grid">
          <div v-for="theme of menuThemes" :key="theme" class="col col-fixed">
            <a :title="theme.name" @click.prevent="changeMenuTheme(theme.name)" class="layout-config-color-option" style="cursor: pointer">
              <span class="color" :style="{'background-color': theme.color}"></span>
              <span class="check flex align-items-center justify-content-center" v-if="menuTheme === theme.name">
                <i class="pi pi-check" style="color: var(--primary-color-text)"></i>
              </span>
            </a>
          </div>
        </div>
        <p v-if="layoutMode==='dark'">Menu themes are only available in light mode by design as large surfaces can emit too much brightness in dark mode.</p>

        <h6>{{ t('app.config.topbarThemes.title') }}</h6>
        <div class="grid">
          <div v-for="theme of topbarThemes" :key="theme" class="col col-fixed">
            <a :title="theme.name" @click.prevent="changeTopbarTheme(theme.name)" class="layout-config-color-option" style="cursor: pointer">
              <span class="color" :style="{'background-color': theme.color}"></span>
              <span class="check flex align-items-center justify-content-center" v-if="topbarTheme === theme.name">
                <i class="pi pi-check" style="color: var(--primary-color-text)"></i>
              </span>
            </a>
          </div>
        </div>

        <h6>{{ t('app.config.componentThemes.title') }}</h6>
        <div class="grid">
          <div v-for="theme of themes" :key="theme" class="col col-fixed">
            <a :title="theme.name" @click.prevent="changeTheme(theme.name)" class="layout-config-color-option" style="cursor: pointer">
              <span class="color" :style="{'background-color': theme.color}"></span>
              <span class="check flex align-items-center justify-content-center" v-if="theme === theme.name">
                <i class="pi pi-check" style="color: var(--primary-color-text)"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import { reactive, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import messages from './app-config-i18n'

export default {
  name: 'AppConfig',
  props: {
    layoutMode: { type: String, default: 'light' },
    theme: { type: String, required: true },
    themes: { type: Array, required: true },
    topbarTheme: { type: String, required: true },
    topbarThemes: { type: Array, required: true },
    menuMode: { type: String, default: 'static' },
    menuTheme: { type: String, required: true },
    menuThemes: { type: Array, required: true },
    inlineMenuPosition: { type: String, default: 'top' },
    inputStyle: { type: String, default: 'outlined' },
    ripple: { type: Boolean, default: true },
    rtl: { type: Boolean, default: false },
    rtlConfigurable: { type: Boolean, default: null }
  },
  emits: [
    'update:layoutMode',
    'update:theme',
    'update:topbarTheme',
    'update:menuMode',
    'update:menuTheme',
    'update:inlineMenuPosition',
    'update:inputStyle',
    'update:ripple',
    'update:rtl'
  ],
  setup (props, { emit }) {
    const route = useRoute()
    const { t } = useI18n({ useScope: 'global', messages })

    const appConfig = reactive({
      active: false,
      scale: 14,
      scales: [12, 13, 14, 15, 16],
      outsideClickListener: null,
      containerClass: computed(() => ['layout-config', { 'layout-config-active': appConfig.active }])
    })

    const themeConfigurationMethods = {
      changeLayoutMode: value => emit('update:layoutMode', value),
      changeTheme: value => emit('update:theme', value),
      changeTopbarTheme: value => emit('update:topbarTheme', value),
      changeMenuMode: value => emit('update:menuMode', value),
      changeMenuTheme: value => emit('update:menuTheme', value),
      changeInlineMenuPosition: value => emit('update:inlineMenuPosition', value),
      changeInputStyle: value => emit('update:inputStyle', value),
      changeRipple: value => emit('update:ripple', value),
      changeRtl: value => emit('update:rtl', value),
      decrementScale () {
        appConfig.scale--
        themeConfigurationMethods.applyScale()
      },
      incrementScale () {
        appConfig.scale++
        themeConfigurationMethods.applyScale()
      },
      applyScale: () => document.documentElement.style.fontSize = appConfig.scale + 'px'
    }

    watch(() => route.path, () => {
      if (appConfig.active) {
        appConfig.active = false
      }
    })

    return { t, appConfig, ...themeConfigurationMethods }
  }
}
</script>
