import axios from 'axios'

export class MLApiService {
  constructor (baseURL) {
    this.$http = axios.create({ baseURL })
  }

  getMetaLearningStatus (projectId) {
    return this.$http.get(`/${projectId}/metalearning`)
      .then(response => response.data)
      .then(response => response && response.metalearning ? response.metalearning : response)
  }

  startMetaLearning (projectId) {
    return this.$http.post(`/${projectId}/metalearning`)
      .then(response => response.data)
  }

  loadTrainingSettings (projectId) {
    return this.$http.get(`/${projectId}/training-settings`)
      .then(response => response.data)
      .then(response => response && response.trainingSettings ? response.trainingSettings : response)
  }

  saveTrainingSettings (projectId, settings) {
    return this.$http.put(`/${projectId}/training-settings`, settings)
      .then(response => response.data)
      .then(response => response && response.trainingSettings ? response.trainingSettings : response)
  }

  getTrainingStatus (projectId) {
    return this.$http.get(`/${projectId}/training`)
      .then(response => response.data)
      .then(response => response && response.training ? response.training : response)
  }

  startTraining (projectId) {
    return this.$http.post(`/${projectId}/training`)
      .then(response => response.data)
  }

  getEvaluation (projectId) {
    return this.$http.get(`/${projectId}/evaluation`)
      .then(response => response.data)
  }

  getEvaluationAnomalies (projectId, modelId) {
    return this.$http.get(`/${projectId}/anomalies/${modelId}`)
      .then(response => response.data)
  }

  selectEvaluationAnomalieWarnBorder (projectId, modelId, warnBorder) {
    return this.$http.put(`/${projectId}/${modelId}/warn-border`, { warnBorder })
      .then(response => response.data)
  }

  selectEvaluationAnomalies (projectId, modelId, selectedAnomalies) {
    return this.$http.put(`/${projectId}/${modelId}/selected-anomalies`, { selectedAnomalies })
      .then(response => response.data)
  }

}
