<template>
  <SelectButton v-model="selectedLayoutMode" :options="layoutModes" dataKey="key" optionLabel="name" optionValue="key">
    <template #option="{ option }">
      <i :class="[{ 'mr-2': !icon, 'm-auto': icon }, `pi ${option.icon}`]"></i>
      <template v-if="!icon">{{ option.name }}</template>
    </template>
  </SelectButton>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppLayoutMode',
  props: {
    modelValue: { type: String },
    icon: { type: Boolean, default: null }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const { modelValue } = toRefs(props)

    const selectedLayoutMode = ref(props.modelValue)
    const layoutModes = computed(() => {
      return [
        { key: 'light', name: t('app.config.layoutMode.options.light'), icon: 'pi-circle-off' },
        { key: 'dark', name: t('app.config.layoutMode.options.dark'), icon: 'pi-circle-on' }
      ]
    })

    watch(selectedLayoutMode, value => emit('update:modelValue', value))
    watch(modelValue, value => selectedLayoutMode.value = value)

    return { selectedLayoutMode, layoutModes }
  }
}
</script>
