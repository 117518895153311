import { ProjectApi } from '../api'
import _ from 'lodash'

const state = {
  projectId: null,
  projectName: null,
  dataSource: null,
  dataSourceStructure: null,
  measurement: null,
  startDateTime: null,
  endDateTime: null,
  variables: null,
  readonly: false,
  dashboardUrl: null
}

const getters = {
  projectId: state => state.projectId,
  projectName: state => state.projectName,
  dataSource: state => state.dataSource,
  dataSourceStructure: state => state.dataSourceStructure,
  measurement: state => state.measurement,
  startDateTime: state => state.startDateTime,
  endDateTime: state => state.endDateTime,
  variables: state => state.variables,
  readonly: state => state.readonly,
  dashboardUrl: state => state.dashboardUrl

}

const mutations = {
  setProjectId: (state, value) => (state.projectId = value),
  setProjectName: (state, value) => (state.projectName = value),
  setDataSource: (state, value) => (state.dataSource = value),
  setDataSourceStructure: (state, value) => (state.dataSourceStructure = value),
  setMeasurement: (state, value) => (state.measurement = value),
  setStartDateTime: (state, value) => (state.startDateTime = value),
  setEndDateTime: (state, value) => (state.endDateTime = value),
  setVariables: (state, value) => (state.variables = value),
  setReadonly: (state, value) => (state.readonly = value),
  setDashboardUrl: (state, value) => (state.dashboardUrl = value)
}

const actions = {
  load ({ commit, dispatch }, projectId) {
    return dispatch('clear').then(() => ProjectApi.get(projectId)
      .then(project => {
        if (project && project.projectId) {
          commit('setProjectId', project.projectId)
          commit('setProjectName', project.name)
          commit('setDashboardUrl', project.dashboard_url)
          console.log('setDashboardUrl', project.dashboard_url)
          if (project.data) {
            const data = project.data
            commit('setDataSource', data.source)
            commit('setDataSourceStructure', data.sourceStructure)
            commit('setMeasurement', data.measurement)
            commit('setStartDateTime', data.startDateTime)
            commit('setEndDateTime', data.endDateTime)
            commit('setVariables', data.variables)
          }
          return Promise.resolve(project.projectId)
        } else {
          return Promise.reject(new Error('Project Id is missing!'))
        }
      }))
  },
  create ({ commit, dispatch }) {
    return dispatch('clear').then(() => ProjectApi.create()
      .then(project => {
        commit('setProjectName', null)
        commit('setDataSource', null)
        commit('setDataSourceStructure', null)
        commit('setMeasurement', null)
        commit('setStartDateTime', null)
        commit('setEndDateTime', null)
        commit('setVariables', null)
        if (project && project.projectId) {
          commit('setProjectId', project.projectId)
          return Promise.resolve(project.projectId)
        } else {
          commit('setProjectId', null)
          return Promise.reject(new Error('Project Id is missing!'))
        }
      }))
  },
  clear ({ commit }) {
    commit('setProjectId', null)
    commit('setProjectName', null)
    commit('setDataSource', null)
    commit('setDataSourceStructure', null)
    commit('setMeasurement', null)
    commit('setStartDateTime', null)
    commit('setEndDateTime', null)
    commit('setVariables', null)
    return Promise.resolve()
  },
  rename ({ getters, commit }, name) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.rename(projectId, name)
        .then(project => {
          commit('setProjectName', project.name)
          return project.name
        })
    } else {
      commit('setProjectName', null)
      return Promise.resolve()
    }
  },
  selectDataSource ({ getters, commit }, sourceId) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.selectDataSource(projectId, sourceId)
        .then(projectData => {
          if (projectData) {
            commit('setDataSource', projectData.sourceId)
            commit('setDataSourceStructure', null)
            commit('setMeasurement', null)
            commit('setStartDateTime', null)
            commit('setEndDateTime', null)
            commit('setVariables', null)
            return projectData.sourceId
          } else {
            return null
          }
        })
    } else {
      return Promise.resolve()
    }
  },
  selectDataSourceStructure ({ getters, dispatch  }, { sourceStructure, sourceStructureOptions }) {
    const determineMeasurement = (sourceStructureOptions, selectedSourceStructure) => {
      let measurement = undefined
      let currentLevel = sourceStructureOptions
      for (const value of selectedSourceStructure) {
        if (currentLevel.key === value.groupKey && currentLevel.items) {
          const index = _.findIndex(currentLevel.items, ['key', value.key])
          if (~index) {
            const item = currentLevel.items[index]
            measurement = item.measurement
            currentLevel = item.group
          }
        }
      }
      return measurement
    }
    const { projectId } = getters

    if (projectId) {
      const measurement = determineMeasurement(sourceStructureOptions, sourceStructure)
      return ProjectApi.selectDataSourceStructure(projectId, sourceStructure, measurement)
        .then(projectData => {
          if (projectData) {
            //commit('setDataSourceStructure', projectData.sourceStructure)
            //commit('setMeasurement', projectData.measurement)
            //commit('setStartDateTime', null)
            //commit('setEndDateTime', null)
            //commit('setVariables', null)
            dispatch('load', projectId)
            return projectData.sourceStructure
          } else {
            return null
          }
        })
    } else {
      return Promise.resolve()
    }
  },
  selectStartDateTime ({ getters, commit }, startDateTime) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.selectDataStartDateTime(projectId, startDateTime)
        .then(projectData => {
          if (projectData && projectData.startDateTime) {
            commit('setStartDateTime', projectData.startDateTime)
            commit('setVariables', null)
            return projectData.startDateTime
          } else {
            return null
          }
        })
    } else {
      return Promise.resolve()
    }
  },
  selectEndDateTime ({ getters, commit }, endDateTime) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.selectDataEndDateTime(projectId, endDateTime)
        .then(projectData => {
          if (projectData && projectData.endDateTime) {
            commit('setEndDateTime', projectData.endDateTime)
            commit('setVariables', null)
            return projectData.endDateTime
          } else {
            return null
          }
        })
    } else {
      return Promise.resolve()
    }
  },
  selectVariables ({ getters, commit }, variables) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.selectDataSetsVariables(projectId, variables)
        .then(projectData => {
          if (projectData) {
            commit('setVariables', projectData.variables)
          } else {
            return null
          }
        })
    } else {
      return Promise.resolve()
    }
  },
  setWriteProtection ({ commit }, writeProtection) {
    commit('setReadonly', writeProtection)
  },
  finish ({ getters }) {
    const { projectId } = getters

    if (projectId) {
      return ProjectApi.finish(projectId)
    } else {
      return Promise.resolve()
    }
  }
}

const data = { namespaced: true, state, getters, mutations, actions }

export default data
