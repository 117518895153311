<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { reactive, computed, watch, toRefs, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppWrapper',
  setup () {
    const vueInstance = getCurrentInstance()
    const store = useStore()
    const { locale } = useI18n({ useScope: 'global' })

    const appConfig = reactive({
      language: computed(() => store.getters['app/language']),
      layoutMode: computed(() => store.getters['app/layoutMode']),
      layoutTheme: computed(() => store.getters['app/layoutTheme']),
      topbarTheme: computed(() => store.getters['app/topbarTheme']),
      menuMode: computed(() => store.getters['app/menuMode']),
      menuTheme: computed(() => store.getters['app/menuTheme']),
      inlineMenuPosition: computed(() => store.getters['app/inlineMenuPosition']),
      inputStyle: computed(() => store.getters['app/inputStyle']),
      ripple: computed(() => store.getters['app/ripple']),
      rtl: computed(() => store.getters['app/rtl'])
    })

    const appDocument = {
      changeStyleSheetUrl (id, value) {
        const element = document.getElementById(id)
        const urlTokens = element.getAttribute('href').split('/')
        urlTokens[urlTokens.length - 2] = value
        const newURL = urlTokens.join('/')
        return this.replaceLink(element, newURL)
      },
      replaceLink (linkElement, href) {
        return new Promise(resolve => {
          const id = linkElement.getAttribute('id')
          const cloneLinkElement = linkElement.cloneNode(true)

          cloneLinkElement.setAttribute('href', href)
          cloneLinkElement.setAttribute('id', id + '-clone')

          linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

          cloneLinkElement.addEventListener('load', () => {
            linkElement.remove()
            cloneLinkElement.setAttribute('id', id)
            resolve()
          })
        })
      },
      replaceLogo (layoutMode) {
        const logo = document.getElementById('logo')
        if (logo) {
          logo.src = layoutMode === 'dark' ? 'layout/images/logo-dark.svg' : 'layout/images/logo-light.svg'
        }
      }
    }

    const appTheme = {
      applyLayoutMode (layoutMode) {
        vueInstance.appContext.config.globalProperties.$appState.layoutMode = layoutMode

        const replaceLayoutLink = () => {
          const layoutLink = document.getElementById('layout-css')
          const layoutHref = 'layout/css/layout-' + layoutMode + '.css'
          return appDocument.replaceLink(layoutLink, layoutHref)
        }

        const replaceThemeLink = () => {
          const themeLink = document.getElementById('theme-css')
          const urlTokens = themeLink.getAttribute('href').split('/')
          urlTokens[urlTokens.length - 1] = 'theme-' + layoutMode + '.css'
          const newURL = urlTokens.join('/')

          return appDocument.replaceLink(themeLink, newURL).then(() => {
            vueInstance.appContext.config.globalProperties.$appState.isNewThemeLoaded = true
          })
        }

        return replaceLayoutLink().then(() => replaceThemeLink())
      },
      applyLayoutTheme (layoutTheme) {
        return appDocument.changeStyleSheetUrl('theme-css', layoutTheme)
      },
      applyTopbarTheme (topbarTheme) {
        if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
          appDocument.replaceLogo('dark')
        } else {
          appDocument.replaceLogo('light')
        }
      },
      applyInputStyle (inputStyle) {
        vueInstance.appContext.config.globalProperties.$primevue.config.inputStyle = inputStyle
      },
      applyRipple (ripple) {
        vueInstance.appContext.config.globalProperties.$primevue.config.ripple = ripple
      },
      applyRtl (rtl) {
        vueInstance.appContext.config.globalProperties.$appState.RTL = rtl
      }
    }

    const { language, layoutMode, layoutTheme, topbarTheme, inputStyle, ripple, rtl } = toRefs(appConfig)

    watch(language, value => {
      locale.value = value
    }, { immediate: true })
    watch(layoutMode, value => appTheme.applyLayoutMode(value))
    watch(layoutTheme, value => appTheme.applyLayoutTheme(value))
    watch(topbarTheme, value => appTheme.applyTopbarTheme(value))
    watch(inputStyle, value => appTheme.applyInputStyle(value), { immediate: true })
    watch(ripple, value => appTheme.applyRipple(value), { immediate: true })
    watch(rtl, value => appTheme.applyRtl(value), { immediate: true })

    onMounted(() => {
      appTheme.applyLayoutMode(appConfig.layoutMode)
          .then(() => appTheme.applyLayoutTheme(appConfig.layoutTheme))
          .then(() => appTheme.applyTopbarTheme(appConfig.topbarTheme))
    })

    return { appConfig }
  }
}
</script>
