<template>
  <div v-if="user" class="layout-inline-menu" :class="{'layout-inline-menu-active': active}">
    <a class="layout-inline-menu-action flex flex-row align-items-center" :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'"
       :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " @click="onClick($event)">
      <img v-if="user.image" :src="user.image" alt="avatar" style="width: 32px; height: 32px;">
      <Avatar v-else icon="pi pi-user" shape="circle" />
      <span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
        <span class="font-bold">{{ user.displayName }}</span>
        <small v-if="user.description">{{ user.description }}</small>
      </span>
      <i class="layout-inline-menu-icon pi pi-angle-down" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}"></i>
    </a>
    <span class="layout-inline-menu-tooltip p-tooltip">
      <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
      <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ user.displayName }}</span>
    </span>

    <transition :name="menuMode === 'horizontal' && !isMobile() ? 'layout-inline-menu-action-panel-horizontal' : 'layout-inline-menu-action-panel'">
      <ul v-show="active"
          :class="['layout-inline-menu-action-panel', { 'layout-inline-menu-action-panel-horizontal' : menuMode === 'horizontal' && !isMobile()}]">
        <slot name="userItems"></slot>

        <slot name="userLayoutMode"></slot>

        <slot name="userLanguage"></slot>

        <slot name="userLogout"></slot>
      </ul>
    </transition>
  </div>
  <div v-else class="layout-inline-menu" :class="{'layout-inline-menu-active': active}"></div>
</template>

<script>
export default {
  name: 'AppInlineMenu',
  props: {
    active: { type: Boolean, default: false },
    menuMode: { type: String },
    inlineMenuKey: { type: String },
    user: { type: Object }
  },
  emits: ['changeInlineMenu'],
  methods: {
    onClick (event) {
      this.$emit('changeInlineMenu', !this.active, this.inlineMenuKey)
      event.preventDefault()
    },
    isMobile () {
      return window.innerWidth <= 991
    }
  },
  computed: {
    isRTL () {
      return this.$appState.RTL
    }
  }
}
</script>
