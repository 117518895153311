<template>
  <div class="p-tabmenu p-component">
    <ul ref="nav" class="p-tabmenu-nav p-reset" role="tablist">
      <template v-for="(item,i) of model" :key="item.label + '_' + i.toString()">
        <li :class="getItemClass(item)" :style="item.style" v-if="visible(item)" role="tab" :aria-selected="isActive(item)" :aria-expanded="isActive(item)">
          <router-link v-if="item.to && !item.disabled" :to="item.to" custom v-slot="{navigate, href}">
            <a :href="href" class="p-menuitem-link" @click="onItemClick($event, item, navigate)" role="presentation" v-ripple>
              <span :class="getItemIcon(item)" v-if="item.icon"></span>
              <span class="p-menuitem-text">{{ item.label }}</span>
            </a>
          </router-link>
          <a v-else :href="item.url" class="p-menuitem-link" :target="item.target" @click="onItemClick($event, item)" role="presentation"
             :tabindex="item.disabled ? null : '0'" v-ripple>
            <span :class="getItemIcon(item)" v-if="item.icon"></span>
            <span class="p-menuitem-text">{{ item.label }}</span>
          </a>
        </li>
      </template>
      <li ref="inkbar" class="p-tabmenu-ink-bar"></li>
    </ul>
  </div>
</template>

<script>
import { DomHandler } from 'primevue/utils'
import Ripple from 'primevue/ripple'

export default {
  props: {
    model: { type: Array, default: null }
  },
  directives: { 'ripple': Ripple },
  computed: {
    activeRoute () {
      return this.$route.path
    }
  },
  methods: {
    onItemClick (event, item, navigate) {
      if (item.disabled) {
        event.preventDefault()
        return
      }

      if (item.command) {
        item.command({
          originalEvent: event,
          item: item
        })
      }

      if (item.to && navigate) {
        navigate(event)
      }
    },
    isActive (item) {
      return this.activeRoute.startsWith(item.to)
    },
    getItemClass (item) {
      return ['p-tabmenuitem', item.class, {
        'p-highlight': this.isActive(item),
        'p-disabled': item.disabled
      }]
    },
    getItemIcon (item) {
      return ['p-menuitem-icon', item.icon]
    },
    visible (item) {
      return (typeof item.visible === 'function' ? item.visible() : item.visible !== false)
    },
    findActiveTabIndex () {
      if (this.model) {
        for (let i = 0; i < this.model.length; i++) {
          if (this.isActive(this.model[i])) {
            return i
          }
        }
      }

      return null
    },
    updateInkBar () {
      let activeTabIndex = this.findActiveTabIndex()
      if (activeTabIndex !== null) {
        let tabHeader = this.$refs.nav.children[activeTabIndex]
        this.$refs.inkbar.style.width = DomHandler.getWidth(tabHeader) + 'px'
        this.$refs.inkbar.style.left = DomHandler.getOffset(tabHeader).left - DomHandler.getOffset(this.$refs.nav).left + 'px'
      } else {
        this.$refs.inkbar.style.width = '0px'
        this.$refs.inkbar.style.left = '0px'
      }

    }
  },
  mounted () {
    this.updateInkBar()
  },
  updated () {
    this.updateInkBar()
  }
}
</script>
