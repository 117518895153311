import DockOneAuthenticationService from './authentication-service'

const state = {
  tokens: {},
  user: {}
}

const getters = {
  isDefined: state => {
    const { uid, username } = state.user
    const { accessToken } = state.tokens
    return !!(uid && username && accessToken)
  },
  tokens: state => state.tokens,
  clientId: state => state.tokens.clientId,
  accessToken: state => state.tokens.accessToken,
  refreshToken: state => state.tokens.refreshToken,
  user: state => state.user
}

const mutations = {
  setTokens: (state, payload) => (state.tokens = payload || {}),
  setUser: (state, payload) => (state.user = payload || {})
}

const actions = {
  login: ({ commit }, { username, password }) => {
    return DockOneAuthenticationService.login(username, password)
      .then(authInfo => {
        commit('setUser', authInfo.profile)
        commit('setTokens', authInfo.tokens)
        return authInfo
      })
      .catch(error => {
        commit('setUser', null)
        commit('setTokens', null)
        return Promise.reject(error)
      })
  },
  refresh: ({ commit, getters }) => {
    const tokens = getters.tokens
    return DockOneAuthenticationService.refresh(tokens)
      .then(authInfo => {
        commit('setUser', authInfo.profile)
        commit('setTokens', authInfo.tokens)
        return authInfo
      })
      .catch(error => {
        commit('setUser', null)
        commit('setTokens', null)
        return Promise.reject(error)
      })
  },
  logout: ({ commit, getters }) => {
    const user = getters.user
    const tokens = getters.tokens
    return DockOneAuthenticationService.logout(tokens)
      .finally(() => {
        commit('setUser', null)
        commit('setTokens', null)
        return user
      })
  }
}

const auth = { namespaced: true, state, getters, mutations, actions }

export default auth
