const messages = {
  de: {
    app: {
      topbar: {
        search: {
          placeholder: 'Suche'
        },
        notifications: {
          headline: {
            title: 'Du hast',
            messages: 'neue Benachrichtigung | neue Benachrichtigung | neue Benachrichtigungen',
            messagesCount: 'keine | eine | {n}'
          }
        }
      }
    }
  },
  en: {
    app: {
      topbar: {
        search: {
          placeholder: 'Search'
        },
        notifications: {
          headline: {
            title: 'You have',
            messages: 'new notification | new notification | new notifications',
            messagesCount: 'no | one | {n}'
          }
        }
      }
    }
  }
}

export default messages
