import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import AutoCBM from '@/autocbm/AutoCBM'

const routes = [
  {
    path: '/',
    component: AutoCBM,
    redirect: { name: 'projects' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/ultima/utilities/Widgets.vue'),
        meta: {
          breadcrumb: [{ label: 'autocbm.dashboard.navigation.label', to: '/dashboard' }]
        }
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('@/autocbm/pages/ProjectsManagement.vue'),
        meta: {
          breadcrumb: [{ label: 'autocbm.projects.navigation.label', to: '/projects' }]
        }
      },
      {
        name: 'project_management',
        path: 'project/:id',
        component: () => import('@/autocbm/pages/ProjectManagement.vue'),
        redirect: { name: 'project_data_load' },
        children: [
          {
            path: 'data/load',
            name: 'project_data_load',
            component: () => import('@/autocbm/components/project/data/load/LoadData'),
            meta: {
              breadcrumb: [
                { label: 'autocbm.projects.management.navigation.label', to: '/project/:id' },
                { label: 'autocbm.projects.management.steps.load', to: '/project/:id/data/load' }
              ]
            }
          },
          {
            path: 'data/select',
            name: 'project_data_select',
            component: () => import('@/autocbm/components/project/data/select/SelectData'),
            meta: {
              breadcrumb: [
                { label: 'autocbm.projects.management.navigation.label', to: '/project/:id' },
                { label: 'autocbm.projects.management.steps.selection', to: '/project/:id/data/select' }
              ]
            }
          },
          {
            path: 'training',
            name: 'project_training',
            component: () => import('@/autocbm/components/project/data/training/Training.vue'),
            meta: {
              breadcrumb: [
                { label: 'autocbm.projects.management.navigation.label', to: '/project/:id' },
                { label: 'autocbm.projects.management.steps.training', to: '/project/:id/training' }
              ]
            }
          },
          {
            path: 'evaluation',
            name: 'project_evaluation',
            component: () => import('@/autocbm/components/project/data/evaluation/Evaluation.vue'),
            meta: {
              breadcrumb: [
                { label: 'autocbm.projects.management.navigation.label', to: '/project/:id' },
                { label: 'autocbm.projects.management.steps.evaluation', to: '/project/:id/evaluation' }
              ]
            }
          }
        ],
        beforeEnter (to, from, next) {
          if (to.params.id === 'undefined') {
            next({ name: 'projects' })
          } else {
            next()
          }
        }
      }
    ],
    beforeEnter (to, from, next) {
      if (store.getters['authentication/isDefined']) {
        next()
      } else {
        next({ name: 'login', query: { redirect: to.path } })
      }
    }
  },
  {
    name: 'project_not_found',
    path: '/project-not-found/:id',
    component: () => import('@/dockone/app/pages/NotFound.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/dockone/app/login/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.APP_BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.params.id === ':id' && from.params.id) {
    next({ name: to.name, params: from.params.id })
  } else {
    next()
  }
})

export default router
