<template>
  <Button type="button" :icon="buttonIcon" :loading="saving" :label="buttonText"
          :class="{
                    'p-button-primary p-disabled': disabled,
                    'p-button-warning' : inputNotSet && !loading,
                    'p-button-success' : inputSuccess && !loading,
                    'p-button-danger' :inputNotSaved && !loading,
                    'p-button-icon-only': !textIcon && !text,
                    'p-button-text': textIcon,
                  }" />
</template>

<script>
import { computed, toRefs, reactive, onMounted, onUpdated } from 'vue'
import { useI18n } from 'vue-i18n'
import messages from './dispatcher-i18n'

export default {
  name: 'DispatchStatus',
  props: {
    icon: { type: String, default: '' },
    loading: { type: Boolean },
    saving: { type: Boolean },
    saved: { type: Boolean },
    error: { type: Boolean },
    disabled: { type: Boolean },
    textIcon: { type: Boolean },
    text: { type: Boolean }
  },
  setup (props, { slots }) {
    const { t } = useI18n({ useScope: 'global', messages })
    const { text, saving, saved, error } = toRefs(props)

    const inputSuccess = computed(() => saved.value && !error.value)
    const inputNotSet = computed(() => !saved.value && !error.value)
    const inputNotSaved = computed(() => error.value)
    const buttonIcon = computed(() => {
      if (props.loading) {
        return 'pi pi-download'
      }
      if (props.icon && inputNotSet.value) {
        return props.icon
      } else if (inputNotSet.value) {
        return 'pi pi-circle'
      } else if (inputSuccess.value) {
        return 'pi pi-check-circle'
      } else {
        return 'pi pi-exclamation-circle'
      }
    })
    const buttonText = computed(() => {
      if (!text.value) {
        return null
      }

      if (saving.value) {
        return t('app.dispatch.status.saving')
      } else if (inputSuccess.value) {
        return t('app.dispatch.status.saved')
      } else if (inputNotSaved.value) {
        return t('app.dispatch.status.error')
      } else {
        return t('app.dispatch.status.notSet')
      }
    })

    const layout = {
      data: reactive({
        defaultInputVisible: false,
        rightInputVisible: false
      }),
      displaySlots () {
        layout.data.defaultInputVisible = !!slots.default
        layout.data.rightInputVisible = !!slots.rightInput
      }
    }

    onMounted(() => layout.displaySlots())
    onUpdated(() => layout.displaySlots())

    return { inputNotSet, inputSuccess, inputNotSaved, buttonIcon, buttonText }
  }
}
</script>
