import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import router from '@/router'

const userAuthenticatedUser = function () {
  const store = useStore()
  const { t } = useI18n({ useScope: 'global' })

  const data = reactive({
    authenticatedUser: computed(() => store.getters['authentication/user']),
    displayName: computed(() => {
      if (data.authenticatedUser) {
        if (data.authenticatedUser.displayName) {
          return data.authenticatedUser.displayName
        }
        if (data.authenticatedUser.firstName && data.authenticatedUser.lastName) {
          return data.authenticatedUser.firstName + ' ' + data.authenticatedUser.lastName
        }

      }
      return t('app.user.unknown')
    }),
    description: computed(() => data.authenticatedUser && data.authenticatedUser.email ? data.authenticatedUser.email : ''),
    image: computed(() => data.authenticatedUser && data.authenticatedUser.image ? data.authenticatedUser.image : null),
    profile: computed(() => ({
      displayName: data.displayName,
      description: data.description,
      image: data.image
    }))
  })

  const actions = {
    logout () {
      store.dispatch('authentication/logout').finally(() => router.push({ name: 'login' }))
    }
  }

  return { data, ...actions }
}

export { userAuthenticatedUser }
