import axios from 'axios'
import store from '@/store'
import router from '@/router'
import authenticationService from '@/dockone/auth/authentication-service'
import { DataApi, ProjectApi, MlApi } from '@/autocbm/api'
import { applyLangRequestHandler, applyAuthenticationRequestHandler, applyAuthenticationResponseHandler } from '@/dockone/auth/axios'

applyLangRequestHandler(authenticationService.axios, store)
applyAuthenticationRequestHandler(axios, store)
applyAuthenticationResponseHandler(axios, store, router)
applyAuthenticationRequestHandler(DataApi.$http, store)
applyAuthenticationResponseHandler(DataApi.$http, store, router)
applyAuthenticationRequestHandler(ProjectApi.$http, store)
applyAuthenticationResponseHandler(ProjectApi.$http, store, router)
applyAuthenticationRequestHandler(MlApi.$http, store)
applyAuthenticationResponseHandler(MlApi.$http, store, router)

axios.defaults.baseURL = process.env.APP_BASE_URL + '/api'
axios.defaults.responseType = 'json'
axios.defaults.responseEncoding = 'utf8'

export default axios
