import { reactive, computed, ref, watch, toRefs, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import EventBus from './event-bus'

const useApp = function (props, appConfig) {
  const vueInstance = getCurrentInstance()
  const route = useRoute()

  const windowWidth = ref(window.innerWidth)
  const windowHeight = ref(window.innerHeight)

  const data = reactive({
    topbarItemClick: false,
    topbarRightClick: false,
    topbarMenuActive: false,
    search: false,
    searchClick: false,
    searchActive: false,
    activeTopbarItem: null,
    menuClick: false,
    menuActive: true,
    mobileTopbarActive: false,
    mobileMenuActive: false,
    inlineMenuClick: false,
    inlineMenuTopActive: false,
    inlineMenuBottomActive: false,
    overlayMenuActive: false,
    rotateMenuButton: false,
    isSlimOrHorItemClick: false,
    breadcrumbVisible: false,
    rightPanelVisible: false,
    rightPanelClick: false,
    rightPanelActive: false,
    footerVisible: false,
    darkMenu: computed(() => appConfig.layoutMode === 'dark'),
    layoutContainerClass: computed(() => [
      'layout-wrapper',
      'layout-menu-' + appConfig.menuTheme + ' layout-topbar-' + appConfig.topbarTheme,
      {
        'layout-menu-static': appConfig.menuIsStatic,
        'layout-menu-overlay': appConfig.menuIsOverlay,
        'layout-menu-overlay-active': data.overlayMenuActive,
        'layout-menu-slim': appConfig.menuIsSlime,
        'layout-menu-horizontal': appConfig.menuIsHorizontal,
        'layout-menu-active': data.menuActive,
        'layout-menu-mobile-active': data.mobileMenuActive,
        'layout-topbar-mobile-active': data.mobileTopbarActive,
        'layout-rightmenu-active': data.rightPanelActive,
        'p-input-filled': appConfig.inputStyle === 'filled',
        'p-ripple-disabled': appConfig.ripple === false,
        'layout-rtl': appConfig.rtl
      }
    ]),
    isDesktop: computed(() => windowWidth.value > 991),
    isMobile: computed(() => windowWidth.value <= 991)
  })

  const actions = {
    onDocumentClick () {
      if (!data.searchClick && data.searchActive) {
        internalActions.hideSearch()
      }

      if (!data.topbarItemClick) {
        data.activeTopbarItem = null
        data.topbarMenuActive = false
      }

      if (!data.menuClick) {
        if (appConfig.menuIsHorizontal || appConfig.menuIsSlime) {
          data.menuActive = false
          data.isSlimOrHorItemClick = false
          EventBus.emit('reset-active-index')
        }

        if (appConfig.menuIsOverlay) {
          data.menuActive = false
        }

        internalActions.hideOverlayMenu()
        internalActions.unblockBodyScroll()
      }

      if (!data.rightPanelClick) {
        data.rightPanelActive = false
      }

      if (!data.inlineMenuClick) {
        data.inlineMenuTopActive = false
        data.inlineMenuBottomActive = false
      }

      data.menuClick = false
      data.searchClick = false
      data.inlineMenuClick = false
      data.topbarItemClick = false
      data.topbarRightClick = false
      data.rightPanelClick = false
    },
    onSearchToggle () {
      data.searchActive = !data.searchActive
      data.searchClick = true
    },
    onSearchClick () {
      data.searchClick = true
    },
    onMenuButtonClick (event) {
      data.menuClick = true
      data.menuActive = !data.menuActive
      data.topbarMenuActive = false
      data.topbarRightClick = true
      data.rotateMenuButton = !data.rotateMenuButton
      data.topbarMenuActive = false

      if (!data.isDesktop) {
        data.mobileMenuActive = !data.mobileMenuActive
        if (data.mobileMenuActive) {
          internalActions.blockBodyScroll()
        } else {
          internalActions.unblockBodyScroll()
        }
      }

      event.preventDefault()
    },
    onTopbarItemClick (event) {
      data.topbarItemClick = true
      data.activeTopbarItem = (data.activeTopbarItem === event.item) ? null : event.item
      event.originalEvent.preventDefault()
    },
    onTopbarMobileButtonClick (event) {
      data.mobileTopbarActive = !data.mobileTopbarActive
      event.preventDefault()
    },
    onRightPanelButtonClick (event) {
      data.rightPanelClick = true
      data.rightPanelActive = !data.rightPanelActive
      event.preventDefault()
    },
    onRightPanelClick () {
      data.rightPanelClick = true
    },
    onHideClick (expanded) {
      data.rightPanelActive = expanded
    },
    onMenuClick () {
      data.menuClick = true
    },
    onRootMenuItemClick (event) {
      data.isSlimOrHorItemClick = !event.isSameIndex
      data.menuActive = !data.menuActive
    },
    onMenuItemClick (event) {
      if (!event.item.items) {
        data.isSlimOrHorItemClick = false
        internalActions.hideOverlayMenu()
        EventBus.emit('reset-active-index')
      }

      if (!event.item.items && (appConfig.menuIsHorizontal || appConfig.menuIsSlime)) {
        data.menuActive = false
      }
    },
    onChangeInlineMenu (event, key) {
      if (key === 'top') {
        if (data.inlineMenuBottomActive) {
          data.inlineMenuBottomActive = false
        }
        data.inlineMenuTopActive = !data.inlineMenuTopActive
      }
      if (key === 'bottom') {
        if (data.inlineMenuTopActive) {
          data.inlineMenuTopActive = false
        }
        data.inlineMenuBottomActive = !data.inlineMenuBottomActive
      }
      data.inlineMenuClick = true
    }
  }

  const internalActions = {
    hideSearch () {
      data.searchActive = false
      data.searchClick = false
    },
    hideOverlayMenu () {
      data.rotateMenuButton = false
      data.overlayMenuActive = false
      data.mobileMenuActive = false
    },
    blockBodyScroll () {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll')
      } else {
        document.body.className += ' blocked-scroll'
      }
    },
    unblockBodyScroll () {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll')
      } else {
        const classNameRegex = new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi')
        document.body.className = document.body.className.replace(classNameRegex, ' ')
      }
    }
  }

  const { layoutMode, menuMode } = toRefs(appConfig)

  watch(() => route.path, () => {
    data.menuActive = appConfig.menuIsStatic && !data.isMobile
    vueInstance.appContext.config.globalProperties.$toast.removeAllGroups()
  })
  watch(layoutMode, value => {
    if (value === 'dark') {
      appConfig.topbarTheme = value
      appConfig.menuTheme = value
    } else {
      if (appConfig.topbarTheme === 'dark') {
        appConfig.topbarTheme = props.defaultTopbarTheme
      }
      if (appConfig.menuTheme === 'dark') {
        appConfig.menuTheme = props.defaultMenuTheme
      }
    }
  }, { immediate: true })
  watch(menuMode, value => {
    data.overlayMenuActive = false
    if (value === 'static') {
      data.menuActive = true
    }
    if (value === 'horizontal') {
      appConfig.inlineMenuPosition = 'bottom'
    }
  }, { immediate: true })

  return { windowWidth, windowHeight, data, ...actions }
}

export { useApp }
