import {ProjectApi} from '../api'

const state = {
    query: null,
    page: 1,
    rows: 10,
    result: {offset: null, limit: null, count: 0, totalCount: 0, data: []},
    algorithmResults: []
}

const getters = {
    query: state => state.query,
    page: state => state.page,
    rows: state => state.rows,
    data: state => state.result.data,
    dataCount: state => state.result.count,
    dataTotalCount: state => state.result.totalCount,
    algorithmResults: state => state.algorithmResults
}

const mutations = {
    setQuery: (state, payload) => (state.query = payload !== '' ? payload : null),
    setPage: (state, payload) => (state.page = payload || 1),
    setRows: (state, payload) => (state.rows = payload || 10),
    setResult: (state, payload) => (state.result = payload || {offset: null, limit: null, count: 0, totalCount: 0, data: []}),
    setAlgorithmResults: (state, payload) => (state.algorithmResults = payload || []),
}

const actions = {
    setQuery({commit}, query) {
        commit('setQuery', query)
    },
    setPage({commit}, offset) {
        commit('setPage', offset)
    },
    setRows({commit}, limit) {
        commit('setRows', limit)
    },
    clearResult({commit}) {
        commit('setResult', null)
    },
    search({getters, commit}, args) {
        let {query, page, rows} = getters
        if (typeof args === 'string') {
            query = args
        }
        if (typeof args === 'object' && typeof args.query === 'string' && typeof args.page === 'number' && typeof args.rows === 'number') {
            query = args.query
            page = args.page
            rows = args.rows
        }
        const offset = (page - 1) * rows

        return ProjectApi.search(query, offset, rows)
            .then(result => {
                commit('setResult', result)
                commit('setAlgorithmResults', extract_algorithm_results(result.data))
                return result
            })
    },
    delete({dispatch}, projectId) {
        return ProjectApi.delete(projectId)
            .then(() => dispatch('search'))
    },
    checkGrafanaSession() {
        // TODO check if session is still valid
        return ProjectApi.getGrafanaSession().then(result => {
            setCookie('grafana_session', result.grafana_session, result.max_age)
        })
    }
}

function setCookie(name,value,seconds) {
    let expires = "";
    if (seconds) {
        let date = new Date();
        date.setTime(date.getTime() + (seconds*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}



function extract_algorithm_results(projects) {
    let values = []
    projects.forEach(project => {
        project.evaluation.forEach(algorithm => {
            let elem = {
                projectName: project.name, recall: algorithm.recall, fScore: algorithm.f1_score, modelId: algorithm.modelId,
                modelName: algorithm.modelName, algorithmName: algorithm.algorithmName, createdAt: algorithm.createdAt, precision: algorithm.precision
            }
            values.push(elem)
        })
    })
    return values
}

const data = {namespaced: true, state, getters, mutations, actions}

export default data
