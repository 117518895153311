import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import app from '@/dockone/app/store'
import authentication from '@/dockone/auth/store'
import autocbm from '@/autocbm/store'

const vuexAppSettings = createPersistedState({
  key: 'autocbm-app-settings',
  storage: window.localStorage,
  reducer: state => ({ app: { language: state.app.language, theme: state.app.theme } })
})

const vuexAppAuthentication = createPersistedState({
  key: 'autocbm-app-authentication',
  storage: window.localStorage,
  reducer: state => ({ authentication: { tokens: state.authentication.tokens, user: state.authentication.user } })
})

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { app, authentication, autocbm },
  plugins: [vuexAppSettings, vuexAppAuthentication]
})

export default store
