import axios from 'axios'

export class DataApiService {
  constructor (baseURL) {
    this.$http = axios.create({ baseURL })
  }

  getDataSources () {
    return this.$http.get('/sources', { params: { limit: 100 } })
      .then(response => response.data)
      .then(response => response.data)
  }

  getDataSourceStructure (sourceId) {
    return this.$http.get(`/sources/${sourceId}/structure`)
      .then(response => response.data)
      .then(data => data.structure)
  }

  getDataSets (sourceId, measurement) {
    return this.$http.get(`/sources/${sourceId}/measurement/${measurement}/data/sets`)
      .then(response => response.data)
  }

  getDataSetsWithTimeInterval (sourceId, measurement, startDateTime, endDateTime) {
    return this.$http.get(`/sources/${sourceId}/measurement/${measurement}/data/sets`, { params: { startDateTime, endDateTime } })
      .then(response => response.data)
  }

  getDataSetsDetails (sourceId, measurement, startDateTime, endDateTime) {
    return this.$http.get(`/sources/${sourceId}/measurement/${measurement}/data/sets/details`, { params: { startDateTime, endDateTime } })
      .then(response => response.data)
  }

}
