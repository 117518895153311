import axios from 'axios'

const appState = axios.create()

const state = {
  language: '',
  theme: {
    layoutMode: '',
    layoutTheme: '',
    topbarTheme: '',
    menuMode: '',
    menuTheme: '',
    inlineMenuPosition: '',
    inputStyle: '',
    ripple: true,
    rtl: false
  },
  online: false
}

const getters = {
  language: state => state.language,
  theme: state => state.theme,
  layoutMode: state => state.theme.layoutMode,
  layoutTheme: state => state.theme.layoutTheme,
  topbarTheme: state => state.theme.topbarTheme,
  menuMode: state => state.theme.menuMode,
  menuTheme: state => state.theme.menuTheme,
  inlineMenuPosition: state => state.theme.inlineMenuPosition,
  inputStyle: state => state.theme.inputStyle,
  ripple: state => state.theme.ripple,
  rtl: state => state.theme.rtl,
  online: state => state.online
}

const mutations = {
  init: (state, { language, layoutMode, layoutTheme, topbarTheme, menuMode, menuTheme, inlineMenuPosition, inputStyle, ripple, rtl }) => {
    if (!state.language) {
      state.language = language || 'de'
    }
    if (!state.theme.layoutMode) {
      state.theme.layoutMode = layoutMode || 'light'
    }
    if (!state.theme.layoutTheme) {
      state.theme.layoutTheme = layoutTheme || 'dockone'
    }
    if (!state.theme.topbarTheme) {
      state.theme.topbarTheme = topbarTheme || 'dockone'
    }
    if (!state.theme.menuMode) {
      state.theme.menuMode = menuMode || 'static'
    }
    if (!state.theme.menuTheme) {
      state.theme.menuTheme = menuTheme || 'dockone'
    }
    if (!state.theme.inlineMenuPosition) {
      state.theme.inlineMenuPosition = inlineMenuPosition || 'bottom'
    }
    if (!state.theme.inputStyle) {
      state.theme.inputStyle = inputStyle || 'outlined'
    }
    if (!state.theme.ripple) {
      state.theme.ripple = !!ripple
    }
    if (!state.theme.rtl) {
      state.theme.rtl = !!rtl
    }
  },
  setLanguage: (state, language) => (state.language = language),
  setLayoutMode: (state, layoutMode) => (state.theme.layoutMode = layoutMode || 'light'),
  setLayoutTheme: (state, layoutTheme) => (state.theme.layoutTheme = layoutTheme || 'dockone'),
  setTopbarTheme: (state, topbarTheme) => (state.theme.topbarTheme = topbarTheme || 'dockone'),
  setMenuMode: (state, menuMode) => (state.theme.menuMode = menuMode || 'static'),
  setMenuTheme: (state, menuTheme) => (state.theme.menuTheme = menuTheme || 'dockone'),
  setInlineMenuPosition: (state, inlineMenuPosition) => (state.theme.inlineMenuPosition = inlineMenuPosition || 'bottom'),
  setInputStyle: (state, inputStyle) => (state.theme.inputStyle = inputStyle || 'outlined'),
  setRipple: (state, ripple) => (state.theme.ripple = !!ripple),
  setRtl: (state, rtl) => (state.theme.rtl = !!rtl),
  setOnline: (state, online) => (state.online = online)
}

const actions = {
  verifyOnline: ({ commit }, url) => {
    return appState.options(url)
      .then(() => commit('setOnline', true))
      .catch(() => commit('setOnline', false))
  }
}

const app = { namespaced: true, state, getters, mutations, actions }

export default app
