const applyLangRequestHandler = (axios, store) => {
  axios.interceptors.request.use(config => {
    const lang = store.getters['app/language']
    if (lang) {
      config.headers['Accept-Language'] = lang
    }
    return config
  }, error => Promise.reject(error))
}

const applyAuthenticationRequestHandler = (axios, store) => {
  axios.interceptors.request.use(config => {
    const lang = store.getters['app/language']
    if (lang) {
      config.headers['Accept-Language'] = lang
    }
    if (config.method === 'get' || config.method === 'post' || config.method === 'put' || config.method === 'delete') {
      const accessToken = store.getters['authentication/accessToken']
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    }
    return config
  }, error => Promise.reject(error))
}

const applyAuthenticationResponseHandler = (axios, store, router) => {
  axios.interceptors.response.use(response => response, error => {
    const errorResponse = error.response
    const originalRequest = error.config
    if (errorResponse && errorResponse.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true
      return store.dispatch('authentication/refresh')
        .catch(error => {
          router.push({ name: 'login', query: { redirect: router.currentRoute.value.path, sessionExpired: true } })
          return Promise.reject(error)
        })
        .then(() => axios.request(originalRequest))
    }
    return Promise.reject(error)
  })
}

export { applyLangRequestHandler, applyAuthenticationRequestHandler, applyAuthenticationResponseHandler }
