import { DataApi } from '../api'

const state = {
  sources: null,
  sourceStructure: null,
  dataSets: null,
  filteredDataSets: null,
  dataSetsDetails: null
}

const getters = {
  sources: state => state.sources,
  sourceStructure: state => state.sourceStructure,
  dataSets: state => state.dataSets,
  filteredDataSets: state => state.filteredDataSets,
  dataSetsDetails: state => state.dataSetsDetails
}

const mutations = {
  setSources: (state, value) => (state.sources = value),
  setSourceStructure: (state, value) => (state.sourceStructure = value),
  setDataSets: (state, value) => (state.dataSets = value),
  setFilteredDataSets: (state, value) => (state.filteredDataSets = value),
  setDataSetsDetails: (state, value) => (state.dataSetsDetails = value)
}

const actions = {
  loadSources ({ commit }) {
    commit('setSources', null)
    return DataApi.getDataSources().then(sources => {
      commit('setSources', sources)
      return sources
    })
  },
  loadSourceStructure ({ commit }, { sourceId }) {
    if (sourceId) {
      commit('setDataSets', null)
      commit('setFilteredDataSets', null)
      commit('setDataSetsDetails', null)
      return DataApi.getDataSourceStructure(sourceId).then(dataSourceStructure => {
        commit('setSourceStructure', dataSourceStructure)
        return dataSourceStructure
      })
    } else {
      commit('setSourceStructure', null)
      return Promise.resolve()
    }
  },
  clearSourceStructure ({ commit }) {
    commit('setSourceStructure', null)
    commit('setDataSets', null)
    commit('setFilteredDataSets', null)
    commit('setDataSetsDetails', null)
    return Promise.resolve()
  },
  loadDataSets ({ commit }, { sourceId, measurement }) {
    if (sourceId && measurement) {
      commit('setDataSets', null)
      commit('setFilteredDataSets', null)
      commit('setDataSetsDetails', null)
      return DataApi.getDataSets(sourceId, measurement).then(dataSets => {
        commit('setDataSets', dataSets)
        return dataSets
      })
    } else {
      commit('setDataSets', null)
      return Promise.resolve()
    }
  },
  loadFilteredDataSets ({ commit }, { sourceId, measurement, startDateTime, endDateTime }) {
    if (sourceId && measurement && startDateTime && endDateTime) {
      commit('setDataSetsDetails', null)
      return DataApi.getDataSetsWithTimeInterval(sourceId, measurement, startDateTime, endDateTime).then(dataSets => {
        commit('setFilteredDataSets', dataSets)
        return dataSets
      })
    } else {
      return Promise.resolve()
    }
  },
  loadDataSetsDetails ({ commit }, { sourceId, measurement, startDateTime, endDateTime }) {
    if (sourceId && measurement && startDateTime && endDateTime) {
      return DataApi.getDataSetsDetails(sourceId, measurement, startDateTime, endDateTime).then(dataSets => {
        commit('setDataSetsDetails', dataSets)
        return dataSets
      })
    } else {
      return Promise.resolve()
    }
  }
}

const data = { namespaced: true, state, getters, mutations, actions }

export default data
