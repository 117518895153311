<template>
  <div class="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1">
    <Breadcrumb :home="home" :model="items" class="layout-breadcrumb p-pl-4 p-py-2" />

    <div v-if="$slots.default" class="layout-breadcrumb-buttons flex align-items-center pr-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBreadcrumb',
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
    }
  },
  computed: {
    items () {
      if (this.$router.currentRoute.value.meta.breadcrumb) {
        const items = []
        for (let item of this.$router.currentRoute.value.meta.breadcrumb) {
          const label = this.$t(item.label)
          items.push({ ...item, label })
        }
        return items
      } else {
        return []
      }
    }
  }
}
</script>
