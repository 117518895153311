<template>
  <div :class="app.data.layoutContainerClass" @click="app.onDocumentClick">
    <AppTopBar :language="appConfig.language.key" :horizontal="appConfig.menuIsHorizontal"
               :topbarMenuActive="app.data.topbarMenuActive" :activeTopbarItem="app.data.activeTopbarItem" :mobileTopbarActive="app.data.mobileTopbarActive"
               :rightPanelVisible="app.data.rightPanelVisible"
               :notifications="notifications" :user="user.data.profile"
               @topbarMobileActive="app.onTopbarMobileButtonClick" @menuButtonClick="app.onMenuButtonClick" @topbarItemClick="app.onTopbarItemClick"
               @rightPanelButtonClick="app.onRightPanelButtonClick"
               :searchVisible="search" :searchActive="app.data.searchActive"
               @searchToggle="app.onSearchToggle" @searchClick="app.onSearchClick" @search="$emit('search', $event)">
      <template v-slot:menu>
        <slot name="topbarMenu" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl" :items="topbarMenu">
          <MegaMenu :model="topbarMenu" class="layout-megamenu" />
        </slot>
      </template>
      <template v-slot:rightItems>
        <slot name="topbarRightItems" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl"></slot>
      </template>
      <template v-slot:userItems>
        <slot name="topbarUserItems" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl" :user="user.data"></slot>
      </template>
      <template v-if="layoutModeSelection" v-slot:userLayoutMode>
        <li class="layout-topbar-action-item layout-mode" @click.stop>
          <a class="flex flex-row align-items-center p-ripple" v-ripple>
            <i class="pi pi-palette" :class="{'mr-3': !appConfig.rtl, 'ml-3': appConfig.rtl}"></i>
            <AppLayoutMode v-model="appConfig.layoutMode" />
          </a>
        </li>
      </template>
      <template v-if="languageSelection" v-slot:userLanguage>
        <li class="layout-topbar-action-item user-language" @click.stop>
          <a class="flex flex-row align-items-center p-ripple" v-ripple>
            <i class="pi pi-flag" :class="{'mr-3': !appConfig.rtl, 'ml-3': appConfig.rtl}"></i>
            <AppLanguage :options="languages" v-model="appConfig.language" />
          </a>
        </li>
      </template>
      <template v-slot:userLogout>
        <slot name="topbarUserLogout" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl">
          <li class="layout-topbar-action-item" @click="user.logout">
            <a class="flex flex-row align-items-center p-ripple" v-ripple>
              <i class="pi pi-power-off" :class="{'mr-3': !appConfig.rtl, 'ml-3': appConfig.rtl}"></i>
              <span>{{ $t('app.user.logout.label') }}</span>
            </a>
          </li>
        </slot>
      </template>
    </AppTopBar>

    <div class="menu-wrapper" v-if="menu">
      <div class="layout-menu-container" @click="app.onMenuClick">
        <AppInlineMenu v-if="appConfig.inlineMenuIsTop || appConfig.inlineMenuIsBoth" v-model:active="app.data.inlineMenuTopActive"
                       :menuMode="appConfig.menuMode" :user="user.data.profile" @changeInlineMenu="app.onChangeInlineMenu" inlineMenuKey="top">
          <template v-slot:userItems>
            <slot name="menuUserItems" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl"></slot>
          </template>
          <template v-if="inlineMenuLayoutModeSelection" v-slot:userLayoutMode>
            <li class="layout-inline-menu-action-item layout-mode" @click.stop>
              <a class="flex flex-row align-items-center">
                <i class="pi pi-palette pi-fw"></i>
                <AppLayoutMode v-model="appConfig.layoutMode" />
              </a>
            </li>
          </template>
          <template v-if="inlineMenuLanguageSelection" v-slot:userLanguage>
            <li class="layout-inline-menu-action-item user-language" @click.stop>
              <a class="flex flex-row align-items-center">
                <i class="pi pi-flag pi-fw"></i>
                <AppLanguage :options="languages" v-model="appConfig.language" icon />
              </a>
              <span class="layout-inline-menu-tooltip p-tooltip">
                <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
                <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ $t('app.user.logout.label') }}</span>
              </span>
            </li>
          </template>
          <template v-slot:userLogout>
            <slot name="menuUserLogout" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl">
              <li class="layout-inline-menu-action-item" @click="user.logout">
                <a class="flex flex-row align-items-center"><i class="pi pi-power-off pi-fw"></i><span>{{ $t('app.user.logout.label') }}</span></a>
                <span class="layout-inline-menu-tooltip p-tooltip">
                <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
                <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ $t('app.user.logout.label') }}</span>
              </span>
              </li>
            </slot>
          </template>
        </AppInlineMenu>

        <AppMenu :model="menu" :menuMode="appConfig.menuMode" :active="app.data.menuActive" :mobileMenuActive="app.data.mobileMenuActive"
                 :isSlimOrHorItemClick="app.data.isSlimOrHorItemClick"
                 @menuitem-click="app.onMenuItemClick" @root-menuitem-click="app.onRootMenuItemClick" />

        <AppInlineMenu v-if="appConfig.inlineMenuIsBottom || appConfig.inlineMenuIsBoth" v-model:active="app.data.inlineMenuBottomActive"
                       :menuMode="appConfig.menuMode" :user="user.data.profile" @changeInlineMenu="app.onChangeInlineMenu" inlineMenuKey="bottom">
          <template v-slot:userItems>
            <slot name="menuUserItems" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl" :user="user.data"></slot>
          </template>
          <template v-if="inlineMenuLayoutModeSelection" v-slot:userLayoutMode>
            <li class="layout-inline-menu-action-item layout-mode" @click.stop>
              <a class="flex flex-row align-items-center">
                <i class="pi pi-palette pi-fw"></i>
                <AppLayoutMode v-model="appConfig.layoutMode" />
              </a>
            </li>
          </template>
          <template v-if="inlineMenuLanguageSelection" v-slot:userLanguage>
            <li class="layout-inline-menu-action-item user-language" @click.stop>
              <a class="flex flex-row align-items-center">
                <i class="pi pi-flag pi-fw"></i>
                <AppLanguage :options="languages" v-model="appConfig.language" icon />
              </a>
            </li>
          </template>
          <template v-slot:userLogout>
            <slot name="menuUserLogout" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl">
              <li class="layout-inline-menu-action-item" @click="user.logout">
                <a class="flex flex-row align-items-center"><i class="pi pi-power-off pi-fw"></i><span>{{ $t('app.user.logout.label') }}</span></a>
                <span class="layout-inline-menu-tooltip p-tooltip">
                <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
                <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ $t('app.user.logout.label') }}</span>
              </span>
              </li>
            </slot>
          </template>
        </AppInlineMenu>
      </div>
    </div>

    <Toast position="top-center" />
    <ConfirmDialog />

    <div class="layout-main" :class="{ 'without-menu': !menu } ">
      <AppBreadcrumb v-if="breadcrumb || app.data.breadcrumbVisible">
        <slot name="breadcrumb" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl"></slot>
      </AppBreadcrumb>

      <div class="layout-content" ref="content">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

      <AppFooter v-if="app.data.footerVisible">
        <slot name="footer" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl"></slot>
      </AppFooter>
    </div>

    <AppConfig v-if="themeConfigurable" v-model:layoutMode="appConfig.layoutMode" v-model:theme="appConfig.layoutTheme"
               v-model:topbarTheme="appConfig.topbarTheme"
               v-model:menuMode="appConfig.menuMode" v-model:menuTheme="appConfig.menuTheme" v-model:inlineMenuPosition="appConfig.inlineMenuPosition"
               v-model:inputStyle="appConfig.inputStyle" v-model:ripple="appConfig.ripple" v-model:rtl="appConfig.rtl"
               :themes="themes" :topbarThemes="topbarThemes" :menuThemes="menuThemes" />

    <AppRightPanel v-if="app.data.rightPanelVisible" :expanded="app.data.rightPanelActive" @contentClick="app.onRightPanelClick" @hide="app.onHideClick">
      <slot name="rightPanel" :layoutMode="appConfig.layoutMode" :isRtl="appConfig.rtl"></slot>
    </AppRightPanel>
    <div v-if="app.data.mobileMenuActive" class="layout-mask modal-in"></div>
  </div>
</template>

<script>
import AppTopBar from './topbar/AppTopbar'
import AppBreadcrumb from './AppBreadcrumb'
import AppMenu from './menu/AppMenu'
import AppInlineMenu from './menu/AppInlineMenu'
import AppRightPanel from './AppRightPanel'
import AppConfig from './config/AppConfig'
import AppFooter from './AppFooter'
import AppLanguage from './AppLanguage'
import AppLayoutMode from './AppLayoutMode'

import { onMounted, onUpdated } from 'vue'
import { useI18n } from 'vue-i18n'
import { useApp } from './app'
import { useAppConfig } from './app-config'
import { userAuthenticatedUser } from './app-user'
import messages from './app-i18n'

export default {
  name: 'App',
  components: { AppTopBar, AppBreadcrumb, AppMenu, AppInlineMenu, AppRightPanel, AppConfig, AppFooter, AppLanguage, AppLayoutMode },
  props: {
    languages: { type: Array, required: true },
    defaultLang: { type: String, required: true },
    themes: { type: Array, required: true },
    defaultTheme: { type: String, required: true },
    topbarThemes: { type: Array, required: true },
    defaultTopbarTheme: { type: String, required: true },
    menuThemes: { type: Array, required: true },
    defaultMenuTheme: { type: String, required: true },
    defaultLayoutMode: { type: String, required: true },
    defaultMenuMode: { type: String, required: true },
    defaultInlineMenuPosition: { type: String, required: true },
    defaultInputStyle: { type: String, required: true },
    menu: { type: Array, default: () => null },
    topbarMenu: { type: Array, default: () => null },
    breadcrumb: { type: Boolean, default: null },
    search: { type: Boolean, default: null },
    notifications: { type: Array, default: null },
    layoutModeSelection: { type: Boolean, default: null },
    inlineMenuLayoutModeSelection: { type: Boolean, default: null },
    languageSelection: { type: Boolean, default: null },
    inlineMenuLanguageSelection: { type: Boolean, default: null },
    themeConfigurable: { type: Boolean, default: process.env.NODE_ENV !== 'production' }
  },
  emits: ['search'],
  setup (props, { slots }) {
    const { t } = useI18n({ useScope: 'global', messages })
    const { appConfig } = useAppConfig(props)
    const app = useApp(props, appConfig)

    const appCustomization = {
      displaySlots () {
        app.data.breadcrumbVisible = !!slots.breadcrumb
        app.data.rightPanelVisible = !!slots.rightPanel
        app.data.footerVisible = !!slots.footer
      }
    }

    const user = userAuthenticatedUser()

    onMounted(() => appCustomization.displaySlots())
    onUpdated(() => appCustomization.displaySlots())

    return { t, appConfig, app, user }
  }
}
</script>

<style lang="scss">
@import 'App';
</style>
