import { reactive, computed } from 'vue'
import { useStore } from 'vuex'

const useAppConfig = function (props) {
  const store = useStore()

  const appConfig = reactive({
    language: computed({
      get: () => props.languages.find(lang => lang.key === store.getters['app/language']),
      set: lang => store.commit('app/setLanguage', lang.key)
    }),
    theme: computed(() => store.getters['app/theme']),
    layoutMode: computed({
      get: () => store.getters['app/layoutMode'],
      set: layoutMode => store.commit('app/setLayoutMode', layoutMode)
    }),
    layoutTheme: computed({
      get: () => store.getters['app/layoutTheme'],
      set: layoutTheme => store.commit('app/setLayoutTheme', layoutTheme)
    }),
    topbarTheme: computed({
      get: () => store.getters['app/topbarTheme'],
      set: topbarTheme => store.commit('app/setTopbarTheme', topbarTheme)
    }),
    menuMode: computed({
      get: () => store.getters['app/menuMode'],
      set: menuMode => store.commit('app/setMenuMode', menuMode)
    }),
    menuTheme: computed({
      get: () => store.getters['app/menuTheme'],
      set: menuTheme => store.commit('app/setMenuTheme', menuTheme)
    }),
    inlineMenuPosition: computed({
      get: () => store.getters['app/inlineMenuPosition'],
      set: inlineMenuPosition => store.commit('app/setInlineMenuPosition', inlineMenuPosition)
    }),
    inputStyle: computed({
      get: () => store.getters['app/inputStyle'],
      set: inputStyle => store.commit('app/setInputStyle', inputStyle)
    }),
    ripple: computed({
      get: () => store.getters['app/ripple'],
      set: ripple => store.commit('app/setRipple', ripple)
    }),
    rtl: computed({
      get: () => store.getters['app/rtl'],
      set: rtl => store.commit('app/setRtl', rtl)
    }),
    menuIsStatic: computed(() => appConfig.menuMode === 'static'),
    menuIsHorizontal: computed(() => appConfig.menuMode === 'horizontal'),
    menuIsSlime: computed(() => appConfig.menuMode === 'slim'),
    menuIsOverlay: computed(() => appConfig.menuMode === 'overlay'),
    inlineMenuIsTop: computed(() => appConfig.inlineMenuPosition === 'top'),
    inlineMenuIsBottom: computed(() => appConfig.inlineMenuPosition === 'bottom'),
    inlineMenuIsBoth: computed(() => appConfig.inlineMenuPosition === 'both')
  })

  return { appConfig }
}

export { useAppConfig }
